var activeAjaxCount = 0;
var dropdownData;
var userPreference;
var unique=true;
var changeAll=false;


$(".menu-toggle").click(function(e) {
  e.preventDefault();
  $("#wrapper").toggleClass("toggled");
});

function getUserPreference(){
  displayLoader();
  $.ajax({
    url : "/users/self/preference",
    type : 'get',
    success : function success(data) {
      if (data!=null) {
        closeDisplayLoader();
        userPreference=data;
        $(document).trigger('preferenceReceived', [userPreference]);
      }
    }
  });
}

function getNavSummary(){
  $.ajax({
    url: "/getPLReportnav",
    type: 'POST',
    data: $('.navform').serialize(),
    dataType: "JSON",
    success: function (data) {
      $('#result1').text(formatNumberOutput(data[0]));
      $('#result2').text(formatNumberOutput(data[1]));
      $('#result3').text(formatNumberOutput(data[2]));
    }
  });
}

function setUserPreference(userPreference){
  displayLoader();
  $.ajax({
    url : "/users/self/preference",
    type : 'put',
    data: userPreference,
    success : function success(data) {
      if (data!=null) {
        closeDisplayLoader();
        userPreference=data;
        //$(document).trigger('preferenceReceived', [userPreference]);
      }
    }
  });
}
// This function will take the year and month (or year only) and pad 1 as the
// date and return the date object
function getFormattedDateFromMonthAndYear(data) {
  // do regex check for mm/yyyy or yyyy format TODO
  // var patt1 = new RegExp("^(0[1-9]|1[012])[- /.](19|20)\d\d$");
  // var patt2 = new RegExp("^(19|20)\d\d$");
  // var array = new Array();
//  if ( /* patt1.test(data) || patt2.test(data) */true) {
  if ( data !== undefined ) {
    var array = data.split('/');
    if (array.length > 1) {
      // is month/year

      // put year into the third col
      array[2] = array[1];
      array[1] = '01';
    } else if (array.length == 1) {
      array[2] = array[0];
      array[1] = '01';
      array[0] = '01';
    }
  } else {
    alert("Invalid Date Format");
  }
  return array;
}

function displayLoader() {
  activeAjaxCount++;
  if (activeAjaxCount == 1) {
    $.loader({
      className : "blue-with-image-2",
      content : ''
    });
  }
}

//Email Validator
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function closeDisplayLoader() {
  activeAjaxCount--;
  if (activeAjaxCount == 0) {
    $.loader('close');
  }
}
function checkMonthYearRadio() {
  if ($('#month-radio').is(":checked")) {
    $('.year-picker').hide();
    if ($('#enable-time-range-checkbox').is(":checked")) {
      $('.month-picker').hide();
      $('#month-range-picker-div').show();
    } else {
      $('#start-month-picker-div').show();
    }
  } else if ($('#year-radio').is(":checked")) {
    if ($('#enable-time-range-checkbox').is(":checked")) {
      $('.year-picker').hide();
      $('#year-range-picker-div').show();
    } else {
      $('#start-year-picker-div').show();
    }
    $('.month-picker').hide();
  }
}

function getFormData() {
  // TODO: DO VALIDATION!!!
  var requestBody = new Array();
  requestBody = {
    budget_level : $('#budget_amount').val()
  };

  var dateFormatted = getFormattedDateFromMonthAndYear($(
    '#start-month-range-picker-input').val());
  if (parseInt(dateFormatted[0])<10){
    //dateFormatted[0]="0"+dateFormatted[0];
  }
  requestBody['start_month'] = new Date(dateFormatted[2]+"-"+dateFormatted[0]+"-"+dateFormatted[1]).getTime() / 1000;
  var dateFormatted2 = getFormattedDateFromMonthAndYear($(
    '#end-month-range-picker-input').val());
  // if (parseInt(dateFormatted2[0])<10){
     //dateFormatted2[0]="0"+dateFormatted2[0];
  // }
  requestBody['end_month'] = new Date(dateFormatted2[2]+"-"+dateFormatted2[0]+"-"+dateFormatted2[1]).getTime() / 1000;
  return requestBody;
}

function calculateGCIandCOS() {
  $(".split-transaction-cos").blur(function() {
    var gci = $(this).parent().prev().children()[0];
    var amount = $(this).parent().prev().prev().prev().children()[0].value;
    gci.value = parseFloat( parseFloat($(this).val()) + parseFloat(amount) );
  });
  $(".split-transaction-gci").blur(function() {
    var cos = $(this).parent().next().children()[0];
    var amount = $(this).parent().prev().prev().children()[0].value;
    cos.value = parseFloat( parseFloat($(this).val()) - parseFloat(amount) );
  }); 
}

function updateTransaction(transactionID, subcategoryID, categoryID) {
  $.ajax({
    url : "/transactions/" + transactionID,
    type : 'put',
    data : {
      subcategory_id : subcategoryID
    },
    success : function success(data) {
      if (data.id == transactionID) {
        getTransactionDetails(transactionID);

      }
    }
  });
}

function getCategoryDropdownData(transactionID) {
  displayLoader();

  $.ajax({
    url : "/transaction-categories",
    type : 'get',
    success : function success(data) {
      dropdownData = data;
      populateCategoryDropdown(data, transactionID);
      closeDisplayLoader();
      $(document).trigger('downloadedNewCategoryData');
    }
  });
}

function populateCategoryDropdown(data, transactionID) {
  var arr = Object.keys(data).map(function(k) {
    return data[k];
  });
  var dropdownList = document.createElement('ul');
  dropdownList.setAttribute('class', 'dropdown-menu dropdown-menu-right multi-level');
  dropdownList.setAttribute('role', 'menu');
  dropdownList.setAttribute('aria-labelledby', 'dropdownMenu');
  for (var i = 0; i < arr.length; i++) {
    var dropdownItem = document.createElement('li');
    dropdownItem.setAttribute('class', 'dropdown-submenu');

    var dropdownA = document.createElement('a');
    dropdownA.setAttribute('tabindex', '-1');
    dropdownA.setAttribute('href', '#');
    $(dropdownA).append(arr[i].name);
    $(dropdownItem).append(dropdownA);

    var dropdownUL = document.createElement('ul');
    dropdownUL.setAttribute('class', 'dropdown-menu');
    for (var j = 0; j < arr[i].transaction_subcategories.length; j++) {
      var subcategoryElement = document.createElement('li');

      var subcategoryElementA = document.createElement('a');
      subcategoryElementA.setAttribute('href', 'javascript:void(0)');
      subcategoryElementA.setAttribute('transaction-id', transactionID);
      subcategoryElementA.setAttribute('subcategory-id', arr[i].transaction_subcategories[j].id);
      subcategoryElementA.setAttribute('category-id', arr[i].id);
      subcategoryElementA.setAttribute('class', 'subcategory-selection');

      $(subcategoryElementA).append(arr[i].transaction_subcategories[j].name);

      $(subcategoryElement).append(subcategoryElementA);
      $(dropdownUL).append(subcategoryElement);
    }

    var subcategoryElement = document.createElement('li');

    var subcategoryElementA = document.createElement('a');
    subcategoryElementA.setAttribute('href', 'javascript:void(0)');
    subcategoryElementA.setAttribute('transaction-id', transactionID);
    subcategoryElementA.setAttribute('category-id', arr[i].id);
    subcategoryElementA.setAttribute('class', 'new-subcategory-selection');

    $(subcategoryElementA).append("Create a new subcategory");

    $(subcategoryElement).append(subcategoryElementA);
    $(dropdownUL).append(subcategoryElement);

    $(dropdownItem).append(dropdownUL);
    $(dropdownList).append(dropdownItem);
  }
  $(dropdownList).insertAfter("#change-subcategory-dropdown");
}


function createNewSubcategory(subcatName, catID, transID) {
  $.ajax({
    url : "/transaction-subcategories",
    type : 'post',
    data : {
      category_id : catID,
      name : subcatName
    },
    success : function success(data) {
      if (data.id != null) {
        updateTransaction(transID, data.id, catID);
        $(document).trigger('newSubcategoryCreated');
      } else {
        var alert = document.createElement('div');
        alert.setAttribute('class',
          'alert alert-danger alert-dismissible');
        alert.setAttribute('id', 'create-new-subcat-fail-alert');
        alert.setAttribute('role', 'alert');

        var closeButton = document.createElement('button');
        closeButton.setAttribute('type', 'button');
        closeButton.setAttribute('class', 'close');
        closeButton.setAttribute('data-dismiss', 'alert');
        closeButton.setAttribute('aria-label', 'Close');

        var hiddenSpan = document.createElement('span');
        hiddenSpan.setAttribute('aria-hidden', 'true');
        $(hiddenSpan).append('&times;');

        $(closeButton).append(hiddenSpan);
        $(alert).append(closeButton);
        $(alert).append('The new subcategory could not be created.');

        $('#pl-report-container').prepend(alert);
      }
    }
  });
}

function createNewSplitCells(data) {
  var splitTransactionTr = document.createElement('tr');
  splitTransactionTr.setAttribute('class', 'split-transaction-tr');
  if (data.id !== undefined) {
    splitTransactionTr.setAttribute('transaction-id', data.id);
  }

  var splitTransactionTdDate = document.createElement('td');
  var splitTransactionInputDate = document.createElement('input');
  splitTransactionInputDate.setAttribute('class',
    'form-control split-transaction-date');
  if (data.posted_date !== undefined) {
    var date = new Date(data.posted_date);
    splitTransactionInputDate.value = date.toLocaleDateString("en-US");
  }
  $(splitTransactionTdDate).append(splitTransactionInputDate);
  $(splitTransactionTr).append(splitTransactionTdDate);

  var splitTransactionTdDescription = document.createElement('td');
  var splitTransactionInputDescription = document.createElement('input');
  splitTransactionInputDescription.setAttribute('class',
    'form-control split-transaction-description');
  if (data.description !== undefined) {
    splitTransactionInputDescription.value = data.description;
  }
  $(splitTransactionTdDescription).append(splitTransactionInputDescription);
  $(splitTransactionTr).append(splitTransactionTdDescription);

  var splitTransactionTdIncome = document.createElement('td');
  $(splitTransactionTdIncome).text('Income');
  $(splitTransactionTr).append(splitTransactionTdIncome);

  var splitTransactionTdAmount = document.createElement('td');
  var splitTransactionInputAmount = document.createElement('input');
  splitTransactionInputAmount.setAttribute('class',
    'form-control split-transaction-amount');
  if (data.amount !== undefined) {
    splitTransactionInputAmount.value = data.amount;
  }
  $(splitTransactionTdAmount).append(splitTransactionInputAmount);
  $(splitTransactionTr).append(splitTransactionTdAmount);

  var splitTransactionTdSource = document.createElement('td');
  var splitTransactionInputSource = document.createElement('input');
  splitTransactionInputSource.setAttribute('class',
    'form-control split-transaction-source');
  if (data.transaction_description !== null
    && data.transaction_description !== undefined
    && data.transaction_description.source !== undefined) {
    splitTransactionInputSource.value = data.transaction_description.source;
}
$(splitTransactionTdSource).append(splitTransactionInputSource);
$(splitTransactionTr).append(splitTransactionTdSource);

var splitTransactionTdGCI = document.createElement('td');
var splitTransactionInputGCI = document.createElement('input');
splitTransactionInputGCI.setAttribute('class',
  'form-control split-transaction-gci');
if (data.transaction_description !== null
  && data.transaction_description !== undefined
  && data.transaction_description.gross_commission_income !== undefined) {
  splitTransactionInputGCI.value = data.transaction_description.gross_commission_income;
}
$(splitTransactionTdGCI).append(splitTransactionInputGCI);
$(splitTransactionTr).append(splitTransactionTdGCI);

var splitTransactionTdCOS = document.createElement('td');
var splitTransactionInputCOS = document.createElement('input');
splitTransactionInputCOS.setAttribute('class',
  'form-control split-transaction-cos');
if (data.transaction_description !== null
  && data.transaction_description !== undefined
  && data.transaction_description.cost_of_sales !== undefined) {
  splitTransactionInputCOS.value = data.transaction_description.cost_of_sales;
}
$(splitTransactionTdCOS).append(splitTransactionInputCOS);
$(splitTransactionTr).append(splitTransactionTdCOS);

var splitTransactionTdButton = document.createElement('td');
var splitTransactionButton = document.createElement('button');
splitTransactionButton.setAttribute('class',
  'split-transaction-delete-btn btn btn-danger');
splitTransactionButton.setAttribute('type', 'button');
$(splitTransactionButton).text('Delete');
$(splitTransactionTdButton).append(splitTransactionButton);
$(splitTransactionTr).append(splitTransactionTdButton);

$('#split-transaction-table-body').append(splitTransactionTr);
$('.split-transaction-date').datepicker();
calculateGCIandCOS();
}

function sendUpdatedSplitTransactions(transactionID) {
  var totalAmount = 0;
  for (var i = 0; i < $('#split-transaction-table-body').children('tr').length; i++) {
    var currentTR = $('#split-transaction-table-body').children('tr')[i];
    totalAmount += parseFloat( $(currentTR).find('td>input.split-transaction-amount')[0].value );
    if ( parseFloat($(currentTR).find('td>input.split-transaction-gci')[0].value) - 
      parseFloat($(currentTR).find('td>input.split-transaction-cos')[0].value) != 
      parseFloat($(currentTR).find('td>input.split-transaction-amount')[0].value) ) {
      $('#split-transaction-error').html("The GCI minus the COS must equal the split transaction amount<br/><br/>");
    return;
  } else {
    $('#split-transaction-error').html("");
  }
}
if( totalAmount != $('#transaction-modal-amount').html() ) {
  $('#split-transaction-error').html("The sum of the amounts of your split transactions must meet the transaction amount<br/><br/>");
  return;
} else {
  $('#split-transaction-error').html("");
}

displayLoader();
var bodyArray = new Object();
bodyArray.split_transactions = new Array();
for (var i = 0; i < $('#split-transaction-table-body').children('tr').length; i++) {
  var currentTR = $('#split-transaction-table-body').children('tr')[i];
  //var asdf = $(currentTR).find('td>input.split-transaction-date');
  bodyArray.split_transactions[i] = {
    id : $(currentTR).attr('transaction-id'),
    posted_date : $(currentTR).find('td>input.split-transaction-date')[0].value,
    description : $(currentTR).find(
      'td>input.split-transaction-description')[0].value,
    amount : $(currentTR).find('td>input.split-transaction-amount')[0].value,
    source : $(currentTR).find('td>input.split-transaction-source')[0].value,
    gci : $(currentTR).find('td>input.split-transaction-gci')[0].value,
    cos : $(currentTR).find('td>input.split-transaction-cos')[0].value
  };
}
  // bodyArray['split_transactions'] = new Array();

  $.ajax({
    url : "transactions/split-transaction/" + transactionID,
    type : 'post',
    data : JSON.stringify(bodyArray),
    success : function success(data) {
      if (data != null) {
        getTransactionDetails(transactionID);
      }

      closeDisplayLoader();
    }
  });
}

function sendUpdatedTransactionDescription(transactionID) {
  displayLoader();
  $.ajax({
    url : "/transactions/transaction-description/" + transactionID,
    type : 'post',
    data : {
      source : $('#transaction-modal-source').val(),
      gci : $('#transaction-modal-gci').val(),
      cos : $('#transaction-modal-cos').val()
    },
    success : function success(data) {
      if (data != null) {
        getTransactionDetails(transactionID);
      }
      closeDisplayLoader();
    }
  });
}

function getTransactionDetails(transactionID) {
  displayLoader();
  $
  .ajax({
    url : "/transactions/" + transactionID,
    type : 'get',
    success : function success(data) {
      if (data.id != null) {
        $('#transaction-details-modal').attr('transaction-id',
          data.id);

        if (data.description != null) {
          $('#transaction-modal-description').text(
            data.description);
        }
        if (data.intuit_transaction_id != null) {
          $('#transaction-modal-trans-id').text(
            data.intuit_transaction_id);
        }
        if (data.amount != null && !isNaN(data.amount)) {
          $('#transaction-modal-amount').text(formatNumberOutput(data.amount));
        }
        if (data.description != null) {
          $('#transaction-modal-description').text(
            data.description);
        }
        if (data.posted_date != null
          && !isNaN(Date.parse(data.posted_date))) {
          var date = new Date(data.posted_date);
        $('#transaction-modal-posted-date').text(
          date.toLocaleDateString("en-US"));
      }
      if (data.transaction_subcategory != null) {
        if (data.transaction_subcategory.transaction_categories[0] != null) {
          $('#transaction-modal-subcategory')
          .text(
            data.transaction_subcategory.transaction_categories[0].name
            + " - "
            + data.transaction_subcategory.name);
        } else {
          $('#transaction-modal-subcategory').text(
            data.transaction_subcategory.name);
        }

      }
      if (data.settle_date != null
        && !isNaN(Date.parse(data.settle_date))) {
        var date = new Date(data.settle_date);
      $('#transaction-modal-settle-date').text(
        date.toLocaleDateString("en-US"));
    }
    if (data.financial_subaccount.account_name != null) {
      $('#transaction-modal-account').text(
        data.financial_subaccount.account_name);
    }
            // all information for the transcation description
            if (data.transaction_description != null) {
              if (data.transaction_description.cost_of_sales != null) {
                $('#transaction-modal-cos')
                .val(
                  data.transaction_description.cost_of_sales);
              }
              if (data.transaction_description.gross_commission_income != null) {
                $('#transaction-modal-gci')
                .val(
                  data.transaction_description.gross_commission_income);
              }
              if (data.transaction_description.source != null) {
                $('#transaction-modal-source').val(
                  data.transaction_description.source);
              }
            }
            $('#transaction-description-save-btn').attr(
              'transaction-id', data.transactionID);
            // all information for the split out transactions
            if (data.child_transactions.length > 0) {
              $('#split-transaction-table-body').empty();
              $('#split-transaction-save-btn').attr(
                'transaction-id', data.transactionID);
              for (var i = 0; i < data.child_transactions.length; i++) {
                createNewSplitCells(data.child_transactions[i]);
              }
              $('#split-transaction-row').show();
              $('#split-transaction-add-btn').show();
              $('#source-transaction-row').hide();
            } else if (data.amount > 0) {
              $('#split-transaction-table-body').empty();
              $('#split-transaction-save-btn').attr('transaction-id', data.transactionID);
              $('#split-transaction-row').show();
              $('#split-transaction-add-btn').show();
              $('#source-transaction-row').hide();
            }
            else if (data.amount <= 0) {
              $('#split-transaction-table-body').empty();
              $('#split-transaction-save-btn').attr('transaction-id', data.transactionID);
              $('#split-transaction-row').show();
              $('#split-transaction-add-btn').show();
              //$('#source-transaction-row').show();
            }
            // } else {
            //   $('#split-transaction-table-body').empty();
            //   $('#split-transaction-add-btn').hide();
            //   $('#split-transaction-row').hide();
            //   $('#source-transaction-row').show();
            // }
            if (dropdownData == null) {
              getCategoryDropdownData(transactionID);
            }
          } else {
            alert("Invalid Transaction");
          }
          closeDisplayLoader();
        }
      });
}
function parseCurrency(num) {
	return parseFloat(num.replace("$","").toString().replace(new RegExp(",", 'g'), ""));
}

function formatCurrency(num) {
	return parseFloat(num).toFixed(2)
//    var str = num.toString();
//    var dollar = "0";
//    var decimal = "0";
//    if (str.indexOf(".") > 0) {
//        var parts = str.split(".");
//        dollar = parts[0];
//        decimal = parts[1];
//    } else if (str !== "") {
//        var dollar = str;
//    }
//    decimal += "00";
//    return (dollar + "." + decimal.substr(0, 2));
};

function formatNumberOutput(numberForOutput) {
  return numeral(numberForOutput).format('($0,0.00)');
}

function unformatNumberOutput(numberForOutput) {
	var multiplier = 1;
	if( numberForOutput.startsWith("(") && numberForOutput.endsWith(")") ) {
		multiplier = -1;
	}
	return parseFloat(numberForOutput.replace("$","").
   replace("(","").replace(")","").
   replace(new RegExp(",", 'g'), "")*multiplier);
}

function getDate(dateTime) {
  if( dateTime == "0000-00-00 00:00:00" ) {
    return "";
  }
  var parts = dateTime.split(" ");
  parts = parts[0].split('-');
  var date = new Date(parts[0], parts[1]-1, parts[2]);
  if( isNaN(date) ) {
    return dateTime;
  }
  return date.toLocaleDateString("en-US");
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position){
    position = position || 0;
    return this.substr(position, searchString.length) === searchString;
  };
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(searchString, position) {
    var subjectString = this.toString();
    if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
      position = subjectString.length;
    }
    position -= searchString.length;
    var lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
}

function createAlert(message) {
	var alert = document.createElement('div');
	alert.setAttribute('id', 'nav-alert');
	alert.setAttribute('class', 'alert alert-danger alert-dismissible');
	alert.setAttribute('style', 'margin:0px 20px;');
	alert.setAttribute('role', 'alert');

	var closeButton = document.createElement('button');
	closeButton.setAttribute('type', 'button');
	closeButton.setAttribute('class', 'close');
	closeButton.setAttribute('data-dismiss', 'alert');
	closeButton.setAttribute('aria-label', 'Close');

	var hiddenSpan = document.createElement('span');
	hiddenSpan.setAttribute('aria-hidden', 'true');
	$(hiddenSpan).append('&times;');
	
	var messageDiv = document.createElement('div');
	messageDiv.setAttribute('id', 'nav-alert-div');
	$(messageDiv).html(message);

	$(closeButton).append(hiddenSpan);
	$(alert).append(closeButton);
	$(alert).append(messageDiv);

	setTimeout(function() {
		$(alert).slideUp("slow", function() {
			$(this).remove();
		});
	}, 30000);

	$('#navbar').after(alert);
}


//ConfirmModal Object
var ConfirmModal = function(el){
  if ( !(this instanceof ConfirmModal) ) {
    return new ConfirmModal( el );
  }
  this.el = $(el);
  this.el.on("click", "#confirm-btn", function(event) {
    //var asdf =$(event.target).attr('event-name');
    $(document).trigger($(event.target).attr('event-name'), [event.target]);
  });
  return this;
}

//Fill modal details
ConfirmModal.prototype.fillModalDetails = function (modalTitle, modalBodyMessage, eventName, optKey, optVal, btnType) {
  this.el.find('#modal-title').text(modalTitle);
  this.el.find('#confirm-body-message').text(modalBodyMessage);
  this.el.find('#confirm-btn').attr('event-name', eventName);
  this.el.find('#confirm-btn').attr('class', 'btn '+btnType);
  if (optKey!=null && optVal!=null){
    this.el.find('#confirm-btn').attr(optKey, optVal);
  }
};

//Open Modal
ConfirmModal.prototype.openModal = function () {
  this.el.modal('show');
};
//Close Modal
ConfirmModal.prototype.closeModal = function () {
  this.el.modal('hide');
};

//ResultModal Object
var ResultModal = function(el){
  if ( !(this instanceof ResultModal) ) {
    return new ResultModal( el );
  }
  this.el = $(el);
  this.el.on("click", "#Result-btn", function(event) {
    $(document).trigger($(event.target).attr('event'), [event.target]);
  });
  this.el.on('hidden.bs.modal', function () {
    $(document).trigger('resultModalClosed');
  });
  return this;
}

//Remove all accordions
ResultModal.prototype.fillModalDetails = function (modalTitle, modalBodyMessage) {
  this.el.find('#result-modal-title').text(modalTitle);
  this.el.find('#result-body-message').text(modalBodyMessage);
};

//Open Modal
ResultModal.prototype.openModal = function () {
  this.el.modal('show');
};

//ResultModal Object
var TransitionReauthenticationNoticeModal = function(el){
  if ( !(this instanceof TransitionReauthenticationNoticeModal) ) {
    return new TransitionReauthenticationNoticeModal( el );
  }
  this.el = $(el);
  this.el.on("click", "#Result-btn", function(event) {
    $(document).trigger($(event.target).attr('event'), [event.target]);
  });
  this.el.on('hidden.bs.modal', function () {
    $(document).trigger('resultModalClosed');
  });
  return this;
}

//Remove all accordions
TransitionReauthenticationNoticeModal.prototype.fillModalDetails = function (modalTitle, modalBodyMessage) {
  this.el.find('#result-modal-title').text(modalTitle);
  this.el.find('#result-body-message').text(modalBodyMessage);
};

//Remove all accordions
TransitionReauthenticationNoticeModal.prototype.show = function () {
  this.el.modal('show');
};

//GlobalAJAX Object
var GlobalAJAX = function( opts ) {
  if ( !(this instanceof GlobalAJAX) ) {
    return new GlobalAJAX( opts );
  }
};
//Get Financial Accounts deleted and active
GlobalAJAX.prototype.getFinancialAccounts = function (withDeleted) {
  var dfd;
  if (withDeleted==null) {
    dfd = $.Deferred();
    dfd.resolve([]);
    return dfd.promise();
  }
  return $.ajax( '/financial-accounts', {
    data : { with_deleted: withDeleted }
  }).pipe(function( resp ) {
    return resp;
  });

};

var globalAjax = new GlobalAJAX();
var transitionReauthenticationNoticeModal= new TransitionReauthenticationNoticeModal('#transition-reauthentication-notice-modal');

$( document ).ready(function() {
  if (loggedIn){
    globalAjax.getFinancialAccounts(false).then(function (response) {
      for (var i=0; i<response.length;i++){
        for (var j=0; j<response[i].financial_subaccounts.length;j++){
          if (response[i].financial_subaccounts[j].finicity_account_id==null&&response[i].financial_subaccounts[j].deleted_at==null&&response[i].financial_subaccounts[j].archived_at==null){
            transitionReauthenticationNoticeModal.show();
            return true;
          }
        }
      }
    });
  }
  
  $('#transition-reauthentication-notice-modal').on('hidden.bs.modal', function () {
    $(document).trigger('transitionReauthenticationNoticeClosed');
  });
  $(document).on('transitionReauthenticationNoticeClosed', function(event){
    if (window.location.pathname!='/v/financial-accounts'){
      window.location = "/v/financial-accounts";

    }
  });
});

